<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card :elevation="isEmbedded ? 0 : 2">
      <v-card-title v-if="!isEmbeddedInApp" class="display-2">Welcome!</v-card-title>
      <v-alert type="success" prominent tile>
        All set! You're good to go.
      </v-alert>
      <v-card-text>
        <v-btn x-large color="primary" :to="{name: 'profile'}">View Dashboard</v-btn>
      </v-card-text>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from 'luxon'
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "Profile",
  components: {
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: {name: 'profile'} },
        { text: 'Onboarding', disabled: true },
      ],
      devices: [
        { text: 'Unknown or other', value: 'unknown' },
        { text: 'Garmin', value: 'garmin' },
        { text: 'Apple Health', value: 'apple-health' },
      ],
      selectedDevice: null,
      nameRules: [
        v => !!v || "Please enter your name",
      ],
      showDobPicker: false,
      profile: null,
      dob: null,
      gender: '_',
      privateProfile: '_',
      unit: '_',
      showOk: false,
      currentStep: 1,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {

    async submit() {
      let response = await profileService.keepProfile();
      if (response.data.status == 'OK') {
        this.showOk = true;
      }
      else {
        this.$helpers.toastResponse(this, response.data);
      }
    },

    async getProfile() {
      if (this.user) {
        var response = await profileService.get();
        this.profile = response.data;
        this.dob = this.profile.dob;
      }
    },
    
    handleDobChange(e) {
      //console.log('Date picker emit new dob', e, 'parsed', this.formattedDob);
      this.dob = e;
      this.showDobPicker = false;
    },

    notSet(value) {
      return value == null || value === '' || value == '_' || value === 0;
    },
    
    async submit() {
      if (this.$refs.form.validate()) {
        console.log('this.profile.gender',this.profile.gender)
        await profileService.put({
          name: this.profile.name,
          email: this.profile.email,
          nationality: this.profile.nationality,
          unit: this.notSet(this.unit) ? null : this.unit,
          gender: this.notSet(this.gender) ? null : this.gender,
          dob: this.notSet(this.dob) ? null : this.dob,
          private: this.notSet(this.privateProfile) ? null : this.privateProfile,
          wmm_migration: true,
        });
        this.profileSavedSnackbar = true;
        // reload
        await this.getProfile();
      }
    },
  },
  computed: {
    formattedDob: {
      get() {
        console.log('/// dob ', this.dob);
        return !this.dob ? null : DateTime.fromISO(this.dob).toLocaleString(DateTime.DATE_SHORT);
      },
      set(value) {
        //this.profile.dob = value;
        //console.log('Date picker emit value', value, 'parsed to dob:', this.profile.dob);
      }
    },
    preferencesValid() {
      return !this.notSet(this.privateProfile) && !this.notSet(this.unit);
    },
    isValid() {
      return this.profile && this.profile.email;
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
</style>

